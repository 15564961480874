@import "_variables";

//トップページ
.news-section {
  position: relative;
  margin: 85px auto 0;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  @include tb {
    margin: calcTb(85) auto 0;
    max-width: calcTb(1100);
  }
  @include sp {
    margin-top: 0;
    display: block;
    max-width: calcSp(696);
  }

  &-topics,
  &-new {
    position: relative;
    padding: 24px 40px;
    width: 540px;
    border-radius: 27px;
    box-sizing: border-box;
    background: #f2f0f0;
    @include tb {
      padding: calcTb(24) calcTb(40);
      width: calcTb(540);
      border-radius: calcTb(27);
    }
    @include sp {
      margin-top: calcSp(55);
      padding: calcSp(25);
      width: 100%;
      border-radius: 14px;
    }
    &::after {
      content: "";
      position: absolute;
      top: -29px;
      left: 16px;
      width: 34px;
      height: 54px;
      background: url(../images/common/icon_flag_company.svg) center / 100% no-repeat;
      @include tb {
        top: calcTb(-29);
        left: calcTb(16);
        width: calcTb(34);
        height: calcTb(54);
      }
      @include sp {
        top: -15px;
        left: 6px;
        width: 19px;
        height: 29px;
      }
      .employee &,
      .employee-index &,
      .employee-concept & {
        background: url(../images/common/icon_flag_employee.svg) center / 100% no-repeat;
      }
    }
    h2 {
      padding-bottom: 10px;
      position: relative;
      font-size: 24px;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      @include tb {
        padding-bottom: calcTb(10);
        font-size: calcTb(24);
      }
      @include sp {
        padding-bottom: 0;
        font-size: 16px;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background: #005fde;
        .employee &,
        .employee-index &,
        .employee-concept & {
          background: #00aa55;
        }
      }
      a {
        font-size: 18px;
        color: #005fde;
        @include tb {
          font-size: calcTb(18);
        }
        @include sp {
          font-size: 14px;
        }
        .employee &,
        .employee-index &,
        .employee-concept & {
          color: #00aa55;
        }
      }
    }
  }
  &-topics {
    background: #fffee6;
  }
  &__inner {
    margin-top: 23px;
    @include tb {
      margin-top: calcTb(23);
    }
    @include sp {
      margin-top: 12px;
    }
    ul {
      li {
        p {
          &.newx-text {
            a {
              &:hover {
                opacity: 1;
                color: #005fde;
                text-decoration: underline;
              }
              .employee-index & {
                &:hover {
                  color: #00aa55;
                }
              }
            }
          }
        }
      }
    }
  }
  &::before {
    position: absolute;
    top: 26px;
    right: -210px;
    content: "";
    width: 264px;
    height: 256px;
    background: url(../images/index/parts_bg1.svg) center / 100% no-repeat;
    mix-blend-mode: multiply;
    z-index: 1;
    @include tb {
      top: calcTb(26);
      right: calcTb(-210);
      width: calcTb(264);
      height: calcTb(256);
    }
    @include sp {
      display: none;
    }
    .employee &,
    .employee-index &,
    .employee-concept & {
      background: url(../images/employee/parts_bg1.svg) center / 100% no-repeat;
    }
  }
}

.contents {
  &-section {
    margin: 48px auto 0;
    max-width: 1100px;
    @include tb {
      margin: calcTb(48) auto 0;
      max-width: calcTb(1100);
    }
    @include sp {
      margin: calcSp(100) auto 0;
    }
    h2 {
      text-align: center;
      font-size: 24px;
      @include tb {
        font-size: calcTb(24);
      }
      @include sp {
        font-size: 21px;
      }
    }
  }
}
.itemGroup {
  &-col3 {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include tb {
      margin-top: calcTb(20);
    }
    @include sp {
      margin: 0;
      flex-wrap: wrap;
      justify-content: center;
    }
    li {
      position: relative;
      @include sp {
        margin: calcSp(40) calcSp(15) 0;
      }
      &::before {
        content: "";
        position: absolute;
        top: 12px;
        left: 12px;
        width: 100%;
        height: 100%;
        background-color: #ddedff;
        border-radius: 29px;
        z-index: -1;
        @include tb {
          top: calcTb(12);
          left: calcTb(12);
          border-radius: calcTb(29);
        }
        @include sp {
          top: calcSp(12);
          left: calcSp(12);
          border-radius: calcSp(29);
        }
        .employee &,
        .employee-index &,
        .employee-concept & {
          background-color: #dff2e0;
        }
      }
      a {
        padding-left: 40px;
        width: 328px;
        height: 135px;
        box-sizing: border-box;
        border-radius: 29px;
        border: 4px solid #005fde;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        @include tb {
          padding-left: calcTb(40);
          width: calcTb(328);
          height: calcTb(135);
          border-radius: calcTb(29);
          font-size: calcTb(20);
          line-height: calcTb(30);
        }
        @include sp {
          padding-left: calcSp(30);
          width: calcSp(332);
          height: calcSp(162);
          border-radius: calcSp(29);
          border: calcSp(4) solid #005fde;
          font-size: calcSp(30);
          line-height: calcSp(40);
        }
        &:hover {
          background: #005fde;
          opacity: 1;
          color: #fff;
        }
        .employee &,
        .employee-index &,
        .employee-concept & {
          border: 4px solid #00aa55;
          @include tb {
            border: calcTb(4) solid #00aa55;
          }
          @include sp {
            padding: calcSp(20) 0;
            display: block;
            height: calcSp(206);
            border: calcSp(4) solid #00aa55;
            text-align: center;
            span {
              display: block;
              text-align: center;
            }
          }
          &:hover {
            background: #00aa55;
            opacity: 1;
            color: #fff;
          }
        }
      }
      &:nth-child(1) {
        a {
          padding-right: 40px;
          @include tb {
            padding-right: calcTb(40);
          }
          @include sp {
            padding-right: calcSp(35);
          }
          .employee &,
          .employee-index &,
          .employee-concept & {
            padding-right: 47px;
            @include tb {
              padding-right: calcTb(47);
            }
            @include sp {
              padding-right: 0;
            }
            &::after {
              content: "";
              width: 73px;
              height: 86px;
              background:
                url(../images/employee/contents1_icon1.svg),
                url(../images/employee/contents1_icon1_hover.svg) center / 100% no-repeat;
              background-size:
                cover,
                0 0;
              @include tb {
                width: calcTb(73);
                height: calcTb(86);
              }
              @include sp {
                margin: auto;
                display: block;
                width: calcSp(70);
                height: calcSp(82);
              }
            }
          }
          &::after {
            content: "";
            width: 96px;
            height: 82px;
            background:
              url(../images/index/contents2_icon1.svg),
              url(../images/index/contents2_icon1_hover.svg) center / 100% no-repeat;
            background-size:
              cover,
              0 0;
            @include tb {
              width: calcTb(96);
              height: calcTb(82);
            }
            @include sp {
              width: calcSp(98);
              height: calcSp(82);
            }
          }
          &:hover {
            &::after {
              background-size:
                0 0,
                cover;
            }
          }
        }
      }
      &:nth-child(2) {
        a {
          padding-right: 28px;
          @include tb {
            padding-right: calcTb(28);
          }
          @include sp {
            padding-right: calcSp(31);
          }
          .employee &,
          .employee-index &,
          .employee-concept & {
            padding-right: 31px;
            @include tb {
              padding-right: calcTb(31);
            }
            @include sp {
              padding-right: 0;
            }
            span {
              @include sp {
                margin-left: 0;
              }
            }
            &::after {
              content: "";
              width: 71px;
              height: 71px;
              background: url(../images/employee/contents1_icon2.svg) center / 100% no-repeat;
              @include tb {
                width: calcTb(71);
                height: calcTb(71);
              }
              @include sp {
                position: static;
                margin: calcSp(10) auto 0;
                display: block;
                width: calcSp(102);
                height: calcSp(72);
                background: url(../images/employee/contents1_icon2_sp.svg) center / 100% no-repeat;
              }
            }
            &:hover {
              &::after {
                background-size: 100%;
              }
            }
          }
          span {
            @include sp {
              margin-left: calcSp(-50);
            }
          }
          &::after {
            content: "";
            width: 125px;
            height: 86px;
            background:
              url(../images/index/contents2_icon2.svg),
              url(../images/index/contents2_icon2_hover.svg) center / 100% no-repeat;
            background-size:
              cover,
              0 0;
            @include tb {
              width: calcTb(125);
              height: calcTb(86);
            }
            @include sp {
              position: absolute;
              right: calcSp(28);
              width: calcSp(125);
              height: calcSp(86);
            }
          }
          &:hover {
            &::after {
              background-size:
                0 0,
                cover;
            }
          }
        }
      }
      &:nth-child(3) {
        a {
          padding-right: 59px;
          @include tb {
            padding-right: calcTb(59);
          }
          @include sp {
            padding-right: calcSp(33);
          }
          .employee &,
          .employee-index &,
          .employee-concept & {
            padding-right: 41px;
            @include tb {
              padding-right: calcTb(41);
            }
            @include sp {
              padding-top: calcSp(30);
              padding-right: 0;
            }
            &::after {
              content: "";
              width: 110px;
              height: 88px;
              background:
                url(../images/employee/contents1_icon3.svg),
                url(../images/employee/contents1_icon3.svg) center / 100% no-repeat;
              background-size:
                cover,
                0 0;
              @include tb {
                width: calcTb(110);
                height: calcTb(88);
              }
              @include sp {
                margin: auto;
                display: block;
                width: calcSp(116);
                height: calcSp(94);
              }
            }
          }
          &::after {
            content: "";
            width: 64px;
            height: 88px;
            background:
              url(../images/index/contents2_icon3.svg),
              url(../images/index/contents2_icon3_hover.svg) center / 100% no-repeat;
            background-size:
              cover,
              0 0;
            @include tb {
              width: calcTb(64);
              height: calcTb(88);
            }
            @include sp {
              margin: auto;
              display: block;
              width: calcSp(64);
              height: calcSp(88);
            }
          }
          &:hover {
            &::after {
              background-size:
                0 0,
                cover;
            }
          }
        }
      }
    }
  }
  &-col4 {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include tb {
      margin-top: calcTb(20);
    }
    @include sp {
      margin: 0;
      flex-wrap: wrap;
      justify-content: center;
    }
    li {
      position: relative;
      @include sp {
        margin: calcSp(40) calcSp(15) 0;
      }
      &::before {
        content: "";
        position: absolute;
        top: 12px;
        left: 12px;
        width: 100%;
        height: 100%;
        background-color: #ddedff;
        border-radius: 29px;
        z-index: -1;
        @include tb {
          top: calcTb(12);
          left: calcTb(12);
          border-radius: calcTb(29);
        }
        @include sp {
          top: calcSp(12);
          left: calcSp(12);
          border-radius: calcSp(29);
        }
      }
      a {
        padding-left: 30px;
        width: 240px;
        height: 135px;
        box-sizing: border-box;
        border-radius: 29px;
        border: 4px solid #005fde;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        @include tb {
          padding-left: calcTb(30);
          width: calcTb(240);
          height: calcTb(135);
          border-radius: calcTb(29);
          border: calcTb(4) solid #005fde;
          font-size: calcTb(20);
          line-height: calcTb(30);
        }
        @include sp {
          padding-left: calcSp(30);
          width: calcSp(332);
          height: calcSp(162);
          border-radius: calcSp(29);
          border: calcSp(4) solid #005fde;
          font-size: calcSp(30);
          line-height: calcSp(40);
        }
        &:hover {
          background: #005fde;
          opacity: 1;
          color: #fff;
        }
      }
      &:nth-child(1) {
        a {
          padding-right: 22px;
          @include tb {
            padding-right: calcTb(22);
          }
          @include sp {
            padding-right: calcSp(38);
          }
          &::after {
            content: "";
            width: 90px;
            height: 70px;
            background:
              url(../images/index/contents1_icon1.svg),
              url(../images/index/contents1_icon1_hover.svg) center / 100% no-repeat;
            background-size:
              cover,
              0 0;
            @include tb {
              width: calcTb(90);
              height: calcTb(70);
            }
            @include sp {
              width: calcSp(96);
              height: calcSp(74);
            }
          }
          &:hover {
            &::after {
              background-size:
                0 0,
                cover;
            }
          }
        }
      }
      &:nth-child(2) {
        a {
          padding-right: 32px;
          @include tb {
            padding-right: calcTb(32);
          }
          @include sp {
            padding-right: calcSp(48);
          }
          &::after {
            content: "";
            width: 64px;
            height: 88px;
            background:
              url(../images/index/contents1_icon2.svg),
              url(../images/index/contents1_icon2_hover.svg) center / 100% no-repeat;
            background-size:
              cover,
              0 0;
            @include tb {
              width: calcTb(64);
              height: calcTb(88);
            }
            @include sp {
              width: calcSp(64);
              height: calcSp(88);
            }
          }
          &:hover {
            &::after {
              background-size:
                0 0,
                cover;
            }
          }
        }
      }
      &:nth-child(3) {
        a {
          padding-right: 27px;
          @include tb {
            padding-right: calcTb(27);
          }
          @include sp {
            padding-right: calcSp(41);
          }
          &::after {
            content: "";
            width: 74px;
            height: 90px;
            background:
              url(../images/index/contents1_icon3.svg),
              url(../images/index/contents1_icon3_hover.svg) center / 100% no-repeat;
            background-size:
              cover,
              0 0;
            @include tb {
              width: calcTb(74);
              height: calcTb(90);
            }
            @include sp {
              width: calcSp(74);
              height: calcSp(90);
            }
          }
          &:hover {
            &::after {
              background-size:
                0 0,
                cover;
            }
          }
        }
      }
      &:nth-child(4) {
        a {
          padding-right: 35px;
          @include tb {
            padding-right: calcTb(35);
          }
          @include sp {
            padding-right: calcSp(45);
          }
          &::after {
            content: "";
            width: 74px;
            height: 74px;
            background:
              url(../images/index/contents1_icon4.svg),
              url(../images/index/contents1_icon4_hover.svg) center / 100% no-repeat;
            background-size:
              cover,
              0 0;
            @include tb {
              width: calcTb(74);
              height: calcTb(74);
            }
            @include sp {
              width: calcSp(84);
              height: calcSp(84);
            }
          }
          &:hover {
            &::after {
              background-size:
                0 0,
                cover;
            }
          }
        }
      }
    }
  }
  &-col2 {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include tb {
      margin-top: calcTb(20);
    }
    @include sp {
      margin: calcSp(30) auto 0;
      max-width: calcSp(632);
      justify-content: space-between;
    }
    li {
      a {
        margin: 0 55px;
        position: relative;
        padding-bottom: 30px;
        width: 228px;
        height: 228px;
        box-sizing: border-box;
        border-radius: 114px;
        border: 4px solid #00aa55;
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 600;
        @include tb {
          margin: 0 calcTb(55);
          padding-bottom: calcTb(30);
          width: calcTb(228);
          height: calcTb(228);
          border-radius: calcTb(114);
          border: calcTb(4) solid #00aa55;
        }
        @include sp {
          padding-bottom: calcSp(30);
          margin: auto;
          width: calcSp(288);
          height: calcSp(288);
          border: calcSp(4) solid #00aa55;
        }
        &:hover {
          background: #00aa55;
          opacity: 1;
          h3 {
            color: #fff;
          }
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 237px;
          height: 237px;
          background-color: #dff2e0;
          border-radius: 114px;
          z-index: -1;
          @include tb {
            width: calcTb(237);
            height: calcTb(237);
            border-radius: calcTb(114);
          }
          @include sp {
            width: calcSp(300);
            height: calcSp(300);
            border-radius: calcSp(150);
          }
        }
        h3 {
          padding-bottom: 0.5em;
          font-size: 20px;
          line-height: 32px;
          color: #00aa55;
          @include tb {
            line-height: calcTb(32);
          }
          @include sp {
            font-size: calcSp(30);
            line-height: calcSp(30);
          }
        }
        span {
          margin: auto;
          width: 120px;
          height: 37px;
          font-size: 16px;
          line-height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid #00aa55;
          background: #fff;
          border-radius: 20px;
          @include tb {
            width: calcTb(120);
            height: calcTb(37);
            font-size: calcTb(16);
            line-height: calcTb(28);
            border-radius: calcTb(20);
          }
          @include sp {
            width: calcSp(187);
            height: calcSp(56);
            font-size: calcSp(28);
            line-height: calcSp(34);
            border: 1px solid #00aa55;
          }
        }
      }
      &:nth-child(1) {
        a {
          &::after {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -28px;
            content: "";
            width: 52px;
            height: 101px;
            background: url(../images/employee/contents2_icon1.svg) center / 100% no-repeat;
            @include tb {
              bottom: calcTb(-28);
              width: calcTb(52);
              height: calcTb(101);
            }
            @include sp {
              bottom: calcSp(-24);
              width: calcSp(56);
              height: calcSp(112);
            }
          }
        }
      }
      &:nth-child(2) {
        a {
          &::after {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -25px;
            content: "";
            width: 65px;
            height: 97px;
            background: url(../images/employee/contents2_icon2.svg) center / 100% no-repeat;
            @include tb {
              bottom: calcTb(-25);
              width: calcTb(65);
              height: calcTb(97);
            }
            @include sp {
              bottom: calcSp(-24);
              width: calcSp(80);
              height: calcSp(118);
            }
          }
        }
      }
    }
  }
}

.event-section {
  position: relative;
  margin: 170px auto 0;
  background: #ddedff;
  @include tb {
    margin: calcTb(170) auto 0;
  }
  @include sp {
    margin: calcSp(200) auto 0;
  }
  .employee &,
  .employee-index &,
  .employee-concept & {
    margin: 154px auto 0;
    background: #dff2e0;
    @include tb {
      margin: calcTb(154) auto 0;
    }
    @include sp {
      margin: calcSp(180) auto 0;
    }
  }
  h2 {
    position: relative;
    transform: translateY(-40px);
    text-align: center;
    font-size: 24px;
    @include tb {
      transform: translateY(calcTb(-40));
      font-size: calcTb(24);
    }
    @include sp {
      transform: translateY(-20px);
      font-size: 21px;
    }
    &::before {
      position: absolute;
      top: -60px;
      left: 50%;
      transform: translateX(-50%);
      content: "";
      width: 663px;
      height: 400px;
      background: #ddedff;
      border-radius: 50%;
      @include tb {
        top: calcTb(-60);
        width: calcTb(663);
        height: calcTb(400);
      }
      @include sp {
        top: -28px;
        width: calcSp(680);
        height: calcSp(345);
      }
      .employee &,
      .employee-index &,
      .employee-concept & {
        background: #dff2e0;
      }
    }
    span {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -72px;
        width: 34px;
        height: 54px;
        transform: scale(-1, 1) rotate(12deg);
        background: url(../images/common/icon_flag_company.svg) center / 100% no-repeat;
        @include tb {
          left: calcTb(-72);
          width: calcTb(34);
          height: calcTb(54);
        }
        @include sp {
          top: auto;
          bottom: 0;
          left: calcSp(-60);
          width: calcSp(38);
          height: calcSp(47);
        }
        .employee &,
        .employee-index &,
        .employee-concept & {
          background: url(../images/common/icon_flag_employee.svg) center / 100% no-repeat;
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -70px;
        width: 34px;
        height: 54px;
        transform: rotate(12deg);
        background: url(../images/common/icon_flag_company.svg) center / 100% no-repeat;
        @include tb {
          right: calcTb(-70);
          width: calcTb(34);
          height: calcTb(54);
        }
        @include sp {
          top: auto;
          bottom: 0;
          right: calcSp(-60);
          width: calcSp(38);
          height: calcSp(47);
        }
        .employee &,
        .employee-index &,
        .employee-concept & {
          background: url(../images/common/icon_flag_employee.svg) center / 100% no-repeat;
        }
      }
    }
  }
  &__inner {
    position: relative;
    padding: 40px 0 60px;
    margin: auto;
    max-width: 947px;
    @include tb {
      padding: calcTb(40) 0 calcTb(60);
      max-width: calcTb(947);
    }
    @include sp {
      padding: 0 0 calcSp(60);
    }
    &::before {
      position: absolute;
      content: "";
      top: -175px;
      left: -267px;
      width: 212px;
      height: 232px;
      background: url(../images/index/parts_bg2.svg) center / 100% no-repeat;
      mix-blend-mode: multiply;
      @include tb {
        top: calcTb(-175);
        left: calcTb(-267);
        width: calcTb(212);
        height: calcTb(232);
      }
      .employee &,
      .employee-index &,
      .employee-concept & {
        background: url(../images/employee/parts_bg2.svg) center / 100% no-repeat;
      }
    }
    &::after {
      position: absolute;
      content: "";
      bottom: -84px;
      right: 27px;
      width: 126px;
      height: 121px;
      // background: url(../images/index/parts_bg3.svg) center / 100% no-repeat;
      @include tb {
        top: calcTb(-84);
        left: calcTb(27);
        width: calcTb(126);
        height: calcTb(121);
      }
      @include sp {
        bottom: calcSp(-47);
        right: calcSp(62);
        width: calcSp(100);
        height: calcSp(94);
      }
      .employee &,
      .employee-index &,
      .employee-concept & {
        background: url(../images/employee/parts_bg3.svg) center / 100% no-repeat;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 15px;
      height: 24px;
      @include tb {
        width: calcTb(15);
        height: calcTb(24);
      }
      @include sp {
        width: calcSp(18);
        height: calcSp(30);
      }
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        background: url(../images/common/slider_arrow.svg) center / 100% no-repeat;
        @include sp {
          background: url(../images/common/slider_arrow_sp.svg) center / 100% no-repeat;
        }
        .employee &,
        .employee-index &,
        .employee-concept & {
          background: url(../images/common/slider_arrow_employee.svg) center / 100% no-repeat;
          @include sp {
            background: url(../images/common/slider_arrow_employee_sp.svg) center / 100% no-repeat;
          }
        }
      }
    }
    .swiper-button-prev {
      left: -47px;
      @include tb {
        left: calcTb(-47);
      }
      @include sp {
        left: calcSp(36);
      }
    }
    .swiper-button-next {
      right: -47px;
      @include tb {
        right: calcTb(-47);
      }
      @include sp {
        right: calcSp(36);
      }
      &::after {
        transform: rotate(-180deg);
      }
    }
  }
  &__bnr {
    margin: auto;
    width: 465px;
    @include tb {
      width: calcTb(465);
    }
    @include sp {
      width: calcSp(622);
    }
  }
  .js-eventSlider {
    position: relative;
    overflow: hidden;
    .swiper-slide {
      width: 465px;
      @include tb {
        width: calcTb(465);
      }
      @include sp {
        width: calcSp(622);
      }
    }
  }
  .pc-deactivate {
    .swiper-wrapper {
      justify-content: space-between;
    }
    .swiper-button-prev, .swiper-button-next {
      visibility: hidden;
      @include sp {
        visibility: visible;
      }
    }
  }
}

.post-section {
  margin: 70px auto 0;
  @include tb {
    margin: calcTb(70) auto 0;
  }
  @include sp {
    display: none;
  }
  h2 {
    text-align: center;
    font-size: 24px;
  }
  .post-list {
    position: relative;
    margin: 30px auto 0;
    max-width: 910px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include tb {
      margin: calcTb(30) auto 0;
      max-width: calcTb(910);
    }
    &::before {
      content: "";
      position: absolute;
      bottom: -192px;
      left: -360px;
      width: 384px;
      height: 392px;
      background: url(../images/index/parts_bg4.svg) center / 100% no-repeat;
      mix-blend-mode: multiply;
      @include tb {
        bottom: calcTb(-192);
        left: calcTb(-360);
        width: calcTb(384);
        height: calctb(392);
      }
    }
    li {
      position: relative;
      &:nth-child(1) {
        &::after {
          position: absolute;
          content: "";
          left: -85px;
          bottom: -178px;
          width: 105px;
          height: 216px;
          background: url(../images/index/parts_person1.svg) center / 100% no-repeat;
          @include tb {
            left: calcTb(-85);
            bottom: calcTb(-178);
            width: calcTb(105);
            height: calcTb(216);
          }
        }
      }
      &:nth-child(2) {
        &::after {
          position: absolute;
          content: "";
          left: -100px;
          bottom: -188px;
          width: 109px;
          height: 188px;
          background: url(../images/index/parts_person2.svg) center / 100% no-repeat;
          @include tb {
            left: calcTb(-100);
            bottom: calcTb(-188);
            width: calcTb(109);
            height: calcTb(188);
          }
        }
      }
      &:nth-child(3) {
        &::before {
          position: absolute;
          content: "";
          left: -36px;
          bottom: -241px;
          width: 91px;
          height: 219px;
          background: url(../images/index/parts_person3.svg) center / 100% no-repeat;
          @include tb {
            left: calcTb(-36);
            bottom: calcTb(-241);
            width: calcTb(91);
            height: calcTb(219);
          }
        }
        &::after {
          position: absolute;
          content: "";
          right: -80px;
          bottom: -223px;
          width: 128px;
          height: 236px;
          background: url(../images/index/parts_person4.svg) center / 100% no-repeat;
          @include tb {
            right: calcTb(-80);
            bottom: calcTb(-223);
            width: calcTb(128);
            height: calcTb(236);
          }
        }
        a {
          width: 236px;
          height: 236px;
          background: url(../images/index/icon_fukidashi_2.svg), url(../images/index/icon_fukidashi_2_hover.svg);
          background-size:
            cover,
            0 0;
          @include tb {
            width: calcTb(236);
            height: calcTb(236);
          }
          &:hover {
            opacity: 1;
            background-size:
              0 0,
              cover;
          }
        }
      }
    }
    a {
      width: 235px;
      height: 241px;
      background: url(../images/index/icon_fukidashi.svg), url(../images/index/icon_fukidashi_hover.svg);
      background-size:
        cover,
        0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 600;
      @include tb {
        width: calcTb(235);
        height: calcTb(241);
      }
      &:hover {
        opacity: 1;
        background-size:
          0 0,
          cover;
        h3 {
          color: #fff;
        }
        span {
          background: #fff;
        }
      }
      h3 {
        padding-bottom: 1em;
        font-size: 20px;
        line-height: 32px;
        height: 3em;
        display: flex;
        align-items: center;
        color: #005fde;
        @include tb {
          font-size: calcTb(20);
          line-height: calcTb(32);
        }
      }
      span {
        margin: auto;
        width: 120px;
        height: 37px;
        font-size: 16px;
        line-height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #005fde;
        border-radius: 20px;
        @include tb {
          width: calcTb(120);
          height: calcTb(37);
          font-size: calcTb(16);
          line-height: calcTb(28);
          border-radius: calcTb(20);
        }
      }
    }
  }
}

.employee-concept {
  .box-flex {
    flex-wrap: nowrap;
    figure {
      padding-left: 1em;
      width: 200px;
      flex-shrink: 0;
      @include tb {
        width: calcTb(200);
      }
    }
  }
}
